import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue"

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_icon_warning = _resolveComponent("icon-warning")

  return (_ctx.error)
    ? (_openBlock(), _createBlock(_component_icon_warning, {
        key: 0,
        class: "c-form-icon ml-f6 w-8 h-8 text-warning cursor-pointer mb-2 inline-block"
      }))
    : _createCommentVNode("", true)
}