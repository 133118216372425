
import { computed , defineComponent } from 'vue';
import { tagTypes } from '@/types';
import { useGlobalTranslations } from '@/hooks/use-translations';

const __default__ = defineComponent({
  name: 'CTag',
  props: {
    name: {
      type: String,
      default: tagTypes.USER,
    },
    color: {
      type: String,
      default: '#06D6A0'
    },
    active: {
      type: Boolean,
      default: true,
    },
    isDisabled: {
      type: Boolean,
      default: false,
    },
    tabindex: {
      type: Number,
      required: false,
    },
    firstElement: {
      type: Boolean,
      default: false,
    }
  },
  emits: ['click'],
  setup(props, { emit }) {

    const onEnterClick = (event: KeyboardEvent): void => {
      if (document.activeElement === event.currentTarget) {
        emit('click')
      }
    }

    const marginStyle = computed(() => props.firstElement ? 'm-f5 ml-0' : 'm-f5')

    return {
      marginStyle,
      onEnterClick,
      useGlobalTranslations,
    };
  },
});

import { useCssVars as _useCssVars } from 'vue'
const __injectCSSVars__ = () => {
_useCssVars(_ctx => ({
  "08b9d396": (_ctx.color)
}))}
const __setup__ = __default__.setup
__default__.setup = __setup__
  ? (props, ctx) => { __injectCSSVars__();return __setup__(props, ctx) }
  : __injectCSSVars__

export default __default__