
import {
  computed,
  ComputedRef,
  defineComponent,
  onMounted,
  ref,
  Ref,
} from 'vue';
import { useGlobalTranslations } from '@/hooks/use-translations';
import CFormErrorIcon from '@/components/common/form/form-error-icon/form-error-icon.component.vue';

export default defineComponent({
  name: 'CFormInput',
  components: { CFormErrorIcon },
  props: {
    placeholder: {
      type: String,
      default: '',
    },
    label: {
      type: String,
      default: '',
    },
    modelValue: {
      type: [String, Number],
      default: null,
    },
    required: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: '',
    },
    error: {
      type: String,
      default: null,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    focus: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['update:modelValue'],
  setup(props) {
    const inputTypeNumber: ComputedRef<string | boolean> = computed(
      () => props.type === 'number' && 'c-form__input--number'
    );
    const input: Ref<HTMLElement | null> = ref(null);
    if (props.focus) {
      onMounted(() => {
        input.value?.focus();
      });
    }
    return {
      input,
      inputTypeNumber,
      useGlobalTranslations,
    };
  },
});
