
import { defineComponent } from 'vue';
import IconWarning         from '@/components/icons/icon-warning.component.vue';

export default defineComponent({
  name: 'CFormErrorIcon',
  components: { IconWarning },
  props: {
    error: {
      type: String || Boolean,
      default: false,
    },
  },
});
